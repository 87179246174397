import { useConfig } from '../ConfigContext';

const Formulary = () => {
	// Config
	const configData = useConfig();

	const closeTab = () => {
		window.opener = null;
		window.open("", "_self");
		window.close();
	};

	const Offers = () => {
		if(!configData?.actions?.view_offers) return (<></>)
		return (
			<div className="d-flex justify-content-center text-break flex-column flex-md-row">
				<h5 className="d-flex d-md-none justify-content-center">👇</h5>
				<h5 className="d-none d-md-flex me-2">👉</h5>
				<h5>
					<a href="configData?.actions?.view_offers" download="configData?.actions?.view_offers" target="_blank" rel="noreferrer"  className="btn btn-success btn-lg" >Ver ofertas</a>
				</h5>
			</div>
		)
	}
	const Benefits = () => {
		if(configData?.actions?.benefits){
			const goToFio = () => {
				window.open(configData.actions.benefits, '_blank');
			}
			return (<button className="btn btn-success btn-lg" onClick={goToFio} >Ver Beneficios</button>)
		}
		return (<></>);
	}

	return (
		<form className="card">
			<div className="card-body d-flex flex-column justify-content-evenly">
				<div className="row align-items-center justify-content-center flex-column gap-1 gap-md-4" id="main">
					<div className="col-12 mb-2">
						<h5>{configData?.text?.end_text_1 ?? 'Aproveche las ofertas especiales que tenemos para Ud.'}</h5>
						<Offers />
					</div>
					<div className="col-12">
						<h5>Conozca y acceda a todos los beneficios por pertenecer a la comunidad Nitro+</h5>
						
						<div className="d-flex justify-content-center text-break flex-column flex-md-row">
							<Benefits />
						</div>
					</div>

				</div>

				<div className="row">
					<div className="col-12 d-flex justify-content-center d-flex d-md-none">
						<button className="btn btn-success btn-lg" type="button" onClick={closeTab} >Cerrar</button>
					</div>
				</div>

			</div>
		</form>
	)
}

function End(){
	return (
		<section id="step">
			<Formulary />
		</section>
	)
}

export default End