import {  useNavigate }  from "react-router-dom";
import { useConfig } from '../ConfigContext';
import React, { useState } from "react";
import { Steps } from "./Layout"
import  {  Post }  from "../utils/Requests";
import {  Loader }  from "../utils/Loader";
import Terms from "./includes/TermsConditions";
import  ErrorModal  from "./includes/ErrorModal";


function Formulary(){
	// Config
	const configData = useConfig();
	// Error modal
	const [show, showError] = useState(false);
	const [errorType, setErrorType] = useState(null);

	// Terms conditions modal
	const [showTerms, viewTerms] = useState(false);

	const navigate = useNavigate();
	const [clientWarning, setClientWarning] = useState('hidden');
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		docType: "cuil",
		// gender: "",
		bank: 2,
		employment: 'empleado_publico',
		document: "",
		areaCode: "",
		phone: "",
		email: "",
		client: false
	})
	
	const [formErrors, setFormErrors] = useState({
		firstName: "",
		lastName: "",
		docType: "",
		gender: "",
		document: "",
		areaCode: "",
		phone: "",
		email: ""
	})
	
	const handleInputChange = (event) => {
		const { name, value, checked } = event.target;
	
		// Update form data
		if(event.target.type === 'checkbox'){
			setFormData({
				...formData,
				[name]: checked,
			});
		}else{
			setFormData({
				...formData,
				[name]: value,
			});
		}

		// Clear validation errors if input is valid
		setFormErrors({
			...formErrors,
			[name]: "",
		});
	
		switch (name) {
			case 'firstName':
				if(value === ''){
					setFormErrors({...formErrors,
						firstName: "El nombre es un dato requerido",
					});
				}
			break;
			case 'email':
				if(value === ''){
					setFormErrors({...formErrors,
						email: "El E-Mail es un dato requerido",
					});
				}
			break;
			case 'lastName':
				if(value === ''){
					setFormErrors({...formErrors,
						lastName: "El apellido es un dato requerido",
					});
				}
			break;

			case 'docType':
				if(value === ''){
					setFormErrors({...formErrors,
						docType: "Debes seleccionar un tipo de documento",
					});
				}
			break;

			case 'gender':
				if(value === ''){
					setFormErrors({...formErrors,
						gender: "El genero es requerido",
					});
				}
			break;

			case 'document':
				if(value === ''){
					setFormErrors({...formErrors,
						document: "Debes indicar un número de documento",
					});
				}
			break;
			case 'areaCode':
			case 'phone':
				if(value === ''){
					setFormErrors({...formErrors,
						areaCode: "El teléfono es requerido",
					});
				}
			break;
			case 'client':
				if(checked) setClientWarning()
				else setClientWarning('hidden')
			break;
			default: break;
		}
		
		
	}
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		Loader.Loading('form.card');
	
		// Perform validation before submitting the form
		const validationErrors = Object.keys(formData).reduce((errors, name) => {
			let ele = document.querySelector(`[name="${name}"]`);
			if (formData[name] === "" && ele.required) {
				if(['areaCode','phone'].indexOf(name) === -1){
					errors[name] = `${ele.getAttribute("placeholder")} es un dato requerido.`;
				}else{
					errors['areaCode'] = `${ele.getAttribute("placeholder")} es un dato requerido.`;
				}
			} else if (name === "email" && formData[name] !== '' && !/^\S+@\S+\.\S+$/.test(formData[name])) {
				errors[name] = "La dirección E-Mail no es válida.";
			}
			return errors;
		}, {});
	
		// const termsConditions = document.querySelector('input[name="termsConditions"]');
		// if(!termsConditions.checked) validationErrors['termsConditions'] = "Debes aceptar los terminos y condiciones";
		// Update form errors
		setFormErrors(validationErrors);
	
		// Check if there are any validation errors
		if (Object.values(validationErrors).every((error) => error === "")) {
			event.target.disabled = true;
			// Perform custom business logic or submit the form

			let library = {
				firstName: 'firstname',
				lastName: 'lastname',
				gender: 'gender',
				document: formData['docType'],
				areaCode: 'area_code',
				phone: 'phone',
				email: 'email',
				client: 'client',
				employment: 'employment',
				bank: 'bank',
				cuil: 'cuil',
			}

			let body = {};
			for(let index in formData){
				if(library[index]) body[library[index]] = formData[index];
			}

			
			let response = await Post('request', JSON.stringify(body), { 'Content-Type': 'Application/json' });

			if(response){
				// if(response.offer) {
				if(response.hash) {
					window.requestHash = response.hash;
					window.firstName = formData['firstName'];
					window.lastName = formData['lastName'];
					window.formData = formData;
					return navigate('/profile');
				}

				if (!response.ui_message){
					setErrorType('offer');
					showError(true);
				}else if(response.ui_message){// Parse errors
					let errors = {};
					for(let index in response){
						let error = response[index];
	
						if(Object.keys(formData).indexOf(index) !== -1){
							errors[index] = error;
							continue;
						}
						switch(index){
							case 'area_code':
								errors['areaCode'] = error;
							break;
							case 'dni':
							case 'cuil':
								errors['document'] = error;
							break;
							default: break;
						}
					}
	
					setFormErrors(errors);
	
					if(response.ui_message.toLowerCase().search('ya cuenta con una solicitud') !== -1){
						setErrorType('duplicated');
						showError(true);
					}
				}
			}else{
				setErrorType('');
				showError(true);
			}
		}
		event.target.disabled = false;
		Loader.Loaded('form.card');
	}

	return (
		<form className="card h-100">
			<div className="card-body d-flex flex-column justify-content-between pt-0">
				<div className="row">
					<div className="col-12">
						<img src={configData?.images?.logo ?? ''} className="form-logo" alt="Brand logo"></img>
						<h4>provisto por</h4>
						<img src="images/fio.png" width={120} alt="Nitro logo"></img>
					</div>
				</div>
	
				<div className="row">
					<div className="col-12 d-flex justify-content-center">
						{Steps(4,1)}
					</div>
				</div>

				<div className="row gap-md-2 justify-content-between">
					<div className="col-12">
						<div className="row">
							<div className="col-6">
								<input type="text" className="form-control" placeholder="Nombre" name="firstName" required
									value={formData.firstName} onChange={handleInputChange} />
								<span className="error">{formErrors.firstName}</span>
							</div>
							<div className="col-6">
								<input type="text" className="form-control" placeholder="Apellido" name="lastName" required
									value={formData.lastName} onChange={handleInputChange} />
								<span className="error">{formErrors.lastName}</span>
							</div>
						</div>
					</div>
				
					{/* <div className="col-12 mt-2">
						<select name="docType" className="form-select" placeholder="Tipo de documento" required
							value={formData.docType} onChange={handleInputChange}>
							<option value="dni">DNI</option>
							<option value="cuil">CUIT</option>
							<option value="" disabled>Seleccioná tipo de documento</option>
						</select>
						<span className="error">{formErrors.docType}</span>
					</div> */}
				
					{/* <div className={visibility}>
						<select name="gender" className="form-select" placeholder="Genero"
							value={formData.gender} onChange={handleInputChange}>
							<option value="m">Masculino</option>
							<option value="f">Femenino</option>
							<option value="" disabled>Seleccioná sexo</option>
						</select>
						<span className="error">{formErrors.gender}</span>
					</div> */}

					<div className="col-12 mt-2">
						<input type="text" name="document" pattern="[0-9]*" inputMode="numeric" className="form-control" placeholder="CUIL / CUIT" required 
							value={formData.document} onChange={handleInputChange} />
						<span className="error">{formErrors.document}</span>
					</div>

					<div className="col-12 mt-2">
						<div className="input-group">
							<span className="input-group-text">0</span>
							<input type="tel" name="areaCode" className="form-control" placeholder="Cód. de área" required 
								value={formData.areaCode} onChange={handleInputChange} />
							<span className="input-group-text">15</span>
							<input type="tel" name="phone" className="form-control" placeholder="Celular" required 
								value={formData.phone} onChange={handleInputChange} />
						</div>

						<div className="row">
							<div className="col-12">
								<span className="error">{formErrors.areaCode}</span>
							</div>
						</div>
					</div>

					<div className="col-12 mt-2">
						<input type="email" name="email" className="form-control" placeholder="E-mail" required
							value={formData.email} onChange={handleInputChange} />
						<span className="error">{formErrors.email}</span>
					</div>


					<div className="col-12 mt-2">
						<div className="form-check form-switch text-start">
							<input className="form-check-input" type="checkbox" name="client" id="client"
								checked={formData.client} onChange={handleInputChange} />
							<label className="form-check-label" htmlFor="client">{configData?.text?.home_client_check ?? 'Ya soy cliente'}</label>
						</div>
					</div>

					<div className="col-12 mt-2">
						<h6 className="text-muted">
							<small className={clientWarning}>{configData?.text?.home_client_warning ?? ''}</small>
						</h6>
					</div>

					<div className="col-12 mt-2 text-center">
						<span className="form-check-label" htmlFor="termsConditions">Al continuar declaro que acepto los <button className="terms-text" onClick={()=>{viewTerms(true)}}>Terminos y condiciones</button></span>
					</div>
				</div>
				
				<div className="row">
					<div className="col-12 d-flex justify-content-center">
						<button className="btn btn-success btn-lg" onClick={handleSubmit}>Comenzar</button>
					</div>
				</div>
			</div>

			<ErrorModal show={show} onClose={()=>{showError(false)}} type={errorType} />
			<Terms show={showTerms} onClose={()=>{viewTerms(false)}} />
		</form>
	)
}

function Home(){
	// Config
	const configData = useConfig();
	return (
		<section id="home">
			<div className="d-flex flex-column">
				<div className="h-100 d-grid home-text">
					<div className="align-self-end">
						<h1><strong>{configData?.text?.home_text_1 ?? ''}</strong></h1>
						<h1>{configData?.text?.home_text_2 ?? ''}</h1>
					</div>

					<div className="text-white align-self-end">
						{/* <h2><strong>Requisitos:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel risus ac justo auctor dignissim.</h2> */}
					</div>
				</div>
			</div>

			<div>
				<Formulary />
			</div>
		</section>
	)
}

export default Home