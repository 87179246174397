import { Steps } from "./Layout"


const Formulary = () => {
	return (
		<form className="card">
			<div className="card-body d-flex flex-column justify-content-evenly">
				<div className="row">
					<div className="col-12 d-flex justify-content-center">
						{Steps(4,5)}
					</div>
				</div>

				<div className="row align-items-center justify-content-center flex-column gap-1 gap-md-4" id="main">
					<div className="col-12">
						<h3>¡Felicitaciones{(window.firstName)? ' '+window.firstName:''}{(window.lastName)? ' '+window.lastName:''}! Ya estamos trabajando en su caso.</h3>
					</div>
					<div className="col-12">
						<h5>En las próximas horas lo estaremos contactando para finalizar el proceso.
							Nos especializamos en potenciar comercios como el suyo. 
							Será una gran alegría sumarlo a nuestra comunidad !!!
						</h5>
					</div>
				</div>

				<div className="row">
					<div className="col-12 d-flex justify-content-center">
						<a className="btn btn-success btn-lg" href="/end" >Finalizar</a>
					</div>
				</div>

			</div>
		</form>
	)
}

function FinalStep(){
	return (
		<section id="step">
			<Formulary />
		</section>
	)
}

export default FinalStep