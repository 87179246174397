import Check from "../assets/images/check.svg"
import React from "react";

const Steps = (totalSteps, activeStep = 0)=>{
	const steps = [];
	for(let i = 1; i <= totalSteps; i++){
		let cls = (i <= activeStep)? 'circle active':'circle';
		steps.push(
			<React.Fragment key={i}>
				<span className={cls}>{(i < activeStep)? <img src={Check} width="50%" alt="Check icon" />:i}</span>
			</React.Fragment>
		);
	}

	return (
		<div className="step-container">
			<div className="steps">
				{steps}
			</div>
		</div>
	)
}

export { Steps }