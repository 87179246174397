import React, { createContext, useContext, useState, useEffect } from 'react';
import config from './config.json';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [configData, setConfigData] = useState(null);

  useEffect(() => {
    setConfigData(config);
  }, []);

  return (
    <ConfigContext.Provider value={configData}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);