import { Modal }  from "react-bootstrap";

const Duplicated = ()=>{
	return (
		<span>Ya tenemos una solicitud tuya en análisis, no puedes iniciar una nueva!</span>
	)
}

const Offer = ()=>{
	return (
		<span>No tenemos una oferta para vos.</span>
	)
}

const ErrorModal = props => {
	const {  show, onClose, type } = props;
	let content;

	switch(type){
		case 'duplicated':
			content = <Duplicated />
		break;
		case 'offer':
			content = <Offer />
		break;
		default: 
			content = <span>No pudimos continuar el proceso, intenta nuevamente más tarde.</span>;
		break;
	}
	return (
		<div>
            <Modal show={show} onHide={onClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>¡Lamentamos las molestias!</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    <button variant="secondary" className='btn btn-danger' onClick={onClose}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
	)
}

export default ErrorModal