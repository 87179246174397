import { useState } from "react";
import {  useNavigate }  from "react-router-dom";
import { Steps } from "./Layout"
import {  Loader }  from "../utils/Loader";
import  {  Put }  from "../utils/Requests";
import { useConfig } from '../ConfigContext';


const Formulary = () => {
	// Config
	const configData = useConfig();
	const navigate = useNavigate();

	const isClient = (typeof window.formData === 'undefined')? false:window.formData.client ?? false;
	let currentStep = (isClient)? 2:3;

	const [formData, setFormData] = useState({
		monthly_income: "",
		monthly_outcome: "",
		required_amount: "",
	})
	
	const [formErrors, setFormErrors] = useState({
		monthly_income: "",
		monthly_outcome: "",
		required_amount: "",
	})

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		// Clear validation errors if input is valid
		setFormErrors({
			...formErrors,
			[name]: "",
		});
	
		if(!value.match(/^[0-9]+$/) && value !== ''){
			switch (name) {
				case 'monthly_income':
					setFormErrors({...formErrors,
						monthly_income: "Solo puedes ingresar valores numericos!",
					});
				break;
				case 'monthly_outcome':
					setFormErrors({...formErrors,
						monthly_outcome: "Solo puedes ingresar valores numericos!",
					});
				break;
				case 'required_amount':
					setFormErrors({...formErrors,
						required_amount: "Solo puedes ingresar valores numericos!",
					});
				break;
				default: break;
			}
		}else{
			// Update form data
			setFormData({
				...formData,
				[name]: value,
			});
		}
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		Loader.Loading('form.card');

		let body = {
			'Ingresos mensuales': formData.monthly_income,
			'Gastos mensuales': formData.monthly_outcome,
			required_amount: formData.required_amount
		}

		await Put(`request/${window.requestHash}`, JSON.stringify(body), { 'Content-Type': 'Application/json' });
		
		setTimeout(()=>{
			if(isClient) return navigate('/files');
			return navigate('/success');
		},1000)
	}
	return (
		<form className="card">
			<div className="card-body d-flex flex-column justify-content-evenly">
				<div className="row">
					<div className="col-12 d-flex justify-content-center mb-2">
						{Steps(4,currentStep)}
					</div>
				</div>

				<div className="row align-items-center justify-content-center flex-column gap-1 gap-md-4" id="main">
					<div className="col-12 col-md-8">
						<h6>Ahora vamos a pedirle unos datos básicos acerca de su comercio. Son muy pocos, pero importantes para conocerlo mas y asi preparar la mejor propuesta para su caso.</h6>
					</div>
					<div className="col-12 col-md-6 mt-3 text-start">
						<label htmlFor="monthly_income">Ventas Mensuales Totales</label>
						<div className="input-group">
							<span className="input-group-text">$</span>
							<input type="text" pattern="[0-9]*" inputMode="numeric" name="monthly_income" id="monthly_income" className="form-control" placeholder="100000" autoComplete="off"
								value={formData.monthly_income} onChange={handleInputChange} />
						</div>
						<span className="error">{formErrors.monthly_income}</span>
					</div>

					<div className="col-12 col-md-6 mt-2 text-start">
						<label htmlFor="monthly_outcome">Compra Mensual en {configData.text.business ?? 'nuestro comercio'}</label>
						<div className="input-group">
							<span className="input-group-text">$</span>
							<input type="text" pattern="[0-9]*" inputMode="numeric" name="monthly_outcome" id="monthly_outcome" className="form-control" placeholder="100000" autoComplete="off"
								value={formData.monthly_outcome} onChange={handleInputChange} />
						</div>
						<span className="error">{formErrors.monthly_outcome}</span>
					</div>

					<div className="col-12 col-md-6 mt-2 text-start">
						<label htmlFor="required_amount">Monto estimado de préstamo para mercadería</label>
						<div className="input-group">
							<span className="input-group-text">$</span>
							<input type="text" pattern="[0-9]*" inputMode="numeric" name="required_amount" id="required_amount" className="form-control" placeholder="100000" autoComplete="off"
								value={formData.required_amount} onChange={handleInputChange} />
						</div>
						<span className="error">{formErrors.required_amount}</span>
					</div>
				</div>
				
				<div className="row">
					<div className="col-12 d-flex justify-content-center">
						<button className="btn btn-success btn-lg" onClick={handleSubmit}>Continuar</button>
					</div>
				</div>
			</div>
		</form>
	)
}

function Profile(){
	return (
		<section id="step">
			<Formulary />
		</section>
	)
}

export default Profile