import { Modal }  from "react-bootstrap";

const Tab1 = ()=>{
	return (
		<div className="tab-pane fade show active" id="terminos" role="tabpanel" aria-labelledby="terminos-tab">
			<h5>Bienvenido al servicio de préstamos ofrecido por Nitro+ (en adelante, "la Empresa"). Al utilizar este servicio, usted acepta los siguientes términos y condiciones:</h5>
			
			<ol className="my-5">
				<li className="my-2"><span className="fw-bold">Aceptación de los Términos y Condiciones:</span> al utilizar el servicio de préstamos, usted acepta cumplir con estos términos y condiciones en su totalidad. Si no está de acuerdo con alguno de los términos, por favor, absténgase de utilizar el servicio.</li>
				<li className="my-2"><span className="fw-bold">Recopilación de datos:</span> durante el proceso de registro y solicitud de préstamo, la Empresa recopilará ciertos datos personales, incluidos, entre otros, su nombre, dirección de correo electrónico, número de teléfono, foto de factura de compra y montos aproximados de facturación y del préstamo pretendido. Esta información se recopila únicamente con fines de evaluación crediticia.</li>
				<li className="my-2"><span className="fw-bold">Uso de Datos Personales:</span> los datos personales recopilados se utilizarán únicamente con el propósito de evaluar y procesar su solicitud de préstamo. La Empresa se compromete a no compartir sus datos personales con terceros sin su consentimiento explícito, excepto en los casos previstos por la ley.</li>
				<li className="my-2"><span className="fw-bold">Evaluación crediticia:</span> la recopilación de datos tiene como objetivo realizar una evaluación crediticia preliminar. La decisión de otorgar o no el préstamo queda sujeta a la discreción exclusiva de la Empresa y no es vinculante.</li>
				<li className="my-2"><span className="fw-bold">Seguridad de datos:</span> la Empresa implementará medidas de seguridad adecuadas para proteger sus datos personales contra accesos no autorizados, pérdidas o alteraciones.</li>
				<li className="my-2"><span className="fw-bold">Derechos del usuario:</span> usted tiene derecho a acceder, rectificar, cancelar u oponerse al procesamiento de sus datos personales en cualquier momento. Para ejercer estos derechos, por favor, contáctenos a través de info@fio.org.ar o los medios proporcionados en nuestro portal web <a href="https://fio.org.ar" className="terms-text" target="_blank" rel="noreferrer">https://fio.org.ar</a>.</li>
				<li className="my-2"><span className="fw-bold">Consentimiento del usuario:</span> al utilizar el servicio de préstamos, usted otorga su consentimiento para el procesamiento de sus datos personales de acuerdo con estos términos y condiciones.</li>
				<li className="my-2"><span className="fw-bold">Modificaciones y actualizaciones:</span> la Empresa se reserva el derecho de modificar o actualizar estos términos y condiciones en cualquier momento. Se le notificará sobre los cambios realizados mediante un aviso en nuestro sitio web o por otros medios de comunicación.</li>
				<li className="my-2"><span className="fw-bold">Ley aplicable y jurisdicción:</span> este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República Argentina. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida ante la Justicia Nacional Ordinaria.</li>
			</ol>

			<h5>Al utilizar este servicio, usted reconoce que ha leído, comprendido y aceptado estos términos y condiciones en su totalidad.</h5>
			<h6>Fecha de última actualización: 29 de Marzo de 2024</h6>
		</div>
	)
}

const Terms = props => {
	const {  show, onClose } = props;
	return (
		<div>
            <Modal show={show} onHide={onClose} centered size="lg">
                <Modal.Header closeButton className="text-center bg-white">
					<Modal.Title>
						Términos y Condiciones del Servicio de Préstamos Online
					</Modal.Title>
                </Modal.Header>
                <Modal.Body>
					<div className="tab-content mt-3">
						<Tab1/ >
					</div>
                </Modal.Body>
                <Modal.Footer className="text-center sticky-bottom bg-white">
                    <button variant="secondary" className='btn btn-secondary' onClick={onClose}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
	)
}

export default Terms