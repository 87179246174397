import { Steps } from "./Layout"

const Formulary = () => {
	const link = `${process.env.REACT_APP_CIRENIO_URL}?${process.env.REACT_APP_CIRENIO_PUBLIC}`;
	return (
		<form className="card">
			<div className="card-body d-flex flex-column justify-content-between">
				<div className="row">
					<div className="col-12 d-flex justify-content-center">
						{Steps(3,1)}
					</div>
				</div>

				<div className="row" id="main">
					<div className="col-12">
						<iframe scrolling="yes" src={link} title="Cirenio widget"></iframe>
					</div>
				</div>
			</div>
		</form>
	)
}

function Kyc(){
	return (
		<section id="step">
			<Formulary />
		</section>
	)
}

export default Kyc