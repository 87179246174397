import { Routes, Route, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ConfigProvider, useConfig } from './ConfigContext';
import Home from './components/Home';
import Kyc from './components/Kyc';
import Files from './components/Files';
import Profile from './components/Profile';
import FinalStep from './components/FinalStep';
import End from './components/End';

// css files
import './App.css';
import './assets/css/Steps.css';
import './styles.css';

function Head() {
	const configData = useConfig();
	if (!configData) return (<></>);

	const { title, meta } = configData.head;
	const metaElements = meta.map((metaItem, index) => (
		<meta key={index} property={metaItem.property} content={metaItem.content} />
	));

	return (
		<Helmet>
			<title>{title}</title>
      		{metaElements}
		</Helmet>
	);
}

function Main(){
	const navigate = useNavigate();
	const configData = useConfig();

	const customStyle = {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
	};
	if(configData) customStyle.backgroundImage = `url('${configData.images.background}')`;

	return (
		<div className="App" style={customStyle}>
			<div className="text-start main_logo">
				<img src={configData?.images?.fio_logo ?? 'images/fio.png'} className="ms-3 mb-3" alt="Nitro logo" onClick={() => { navigate('/') }}></img>
			</div>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/kyc" element={<Kyc />} />
				<Route path="/profile" element={<Profile />} />
				<Route path="/files" element={<Files />} />
				<Route path="/success" element={<FinalStep />} />
				<Route path="/end" element={<End />} />
			</Routes>
		</div>
	)
}

function App() {
	return (
		<ConfigProvider>
			<Head />
			<Main />
		</ConfigProvider>
	);
}

export default App;
