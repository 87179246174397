const Loader = new function(){
	this.Loading = (selector)=>{
		let target = selector;
		if(typeof selector === 'string') target = document.querySelector(selector);
		if(!target) return;

		if(target.classList.contains('loading')) return;
		return target.classList.add('loading');
	}

	this.Loaded = (selector)=>{
		let target = selector;
		if(typeof selector === 'string') target = document.querySelector(selector);
		if(!target) return;

		if(!target.classList.contains('loading')) return;
		return target.classList.remove('loading');
	}
}

export { Loader }