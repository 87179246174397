const Token  = new function(){
	this.token = null;
	this.expiration = null;
	this.Get = () => {
		return new Promise((res) => {
			let currentTime = Math.floor(Date.now() / 1000);
			if (this.expiration && this.expiration > currentTime) return res(this.token);
			fetch(`${process.env.REACT_APP_BO_URL}/token?pk=${process.env.REACT_APP_BO_PUBLIC}`, {
				method: 'GET'
			})
			.then(response => {
				response.json().then((data) => {
					if (data.token) this.token = data.token;
					if (data.expiracy) this.expiration = data.expiracy;
					res(this.token);
				});
			}).catch((error) => {
				console.log(error);
				res(null);
			});
		});
	};
}

const Get = (url, parameters)=>{
	return new Promise(async (res)=>{
		let auth = await Token.Get();
		if(!auth) return res(null);

		fetch(`${process.env.REACT_APP_BO_URL}/${url}?`+new URLSearchParams(parameters), {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer '+auth
			}
		}).then((response)=>{
			response.json().then((data)=>{
				res(data);
			});
		}).catch((error)=>{
			console.log(error);
			res(null)
		});
	});
}

const Post = (url, parameters, customHeaders = {})=>{
	return new Promise(async (res)=>{
		let auth = await Token.Get();
		if(!auth) return res(null);

		fetch(`${process.env.REACT_APP_BO_URL}/${url}`, {
			method: 'POST',
			headers: Object.assign({}, customHeaders, {
				'Authorization': 'Bearer '+auth
			}),
			body: parameters
		}).then((response)=>{
			response.json().then((data)=>{
				res(data);
			});
		}).catch((error)=>{
			console.log(error);
			res(null)
		});
	});
}

const Put = (url, parameters, customHeaders = {})=>{
	return new Promise(async (res)=>{
		let auth = await Token.Get();
		if(!auth) return res(null);

		fetch(`${process.env.REACT_APP_BO_URL}/${url}`, {
			method: 'PUT',
			headers: Object.assign({}, customHeaders, {
				'Authorization': 'Bearer '+auth
			}),
			body: parameters
		}).then((response)=>{
			response.json().then((data)=>{
				res(data);
			});
		}).catch((error)=>{
			console.log(error);
			res(null)
		});
	});
}

export { Get, Post, Put, Token }