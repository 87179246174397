import { useNavigate }  from "react-router-dom";
import { useConfig } from '../ConfigContext';
import { useState } from 'react';
import { Steps } from "./Layout"
import { Post }  from "../utils/Requests"
import { Modal }  from "react-bootstrap";
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

const FilesRequiredModal = (props) => {
	const {  show, onClose } = props;
	return (
		<div>
            <Modal show={show} onHide={onClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><b>¡Lamentamos las molestias!</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
					Para continuar, es necesario que suba una factura de compra. O si prefiere puede <a href="/success" class="terms-text">hacerlo más tarde</a>.
                </Modal.Body>
                <Modal.Footer>
                    <button variant="secondary" className='btn btn-danger' onClick={onClose}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
	)
}

const Formulary = () => {
	// Config
	const configData = useConfig();
	const navigate = useNavigate();
	// Modal
	const [showFilesRequired, viewModal] = useState(false);
	// Register the plugin
	registerPlugin(FilePondPluginImagePreview);
	registerPlugin(FilePondPluginFileValidateSize);
	registerPlugin(FilePondPluginFileValidateType);
	const hash = window.requestHash;

	const [files, setFiles] = useState([]);
	let fpond;

	let uploadedFilesTimer;

	const serverConfig = {
		url: process.env.REACT_APP_BO_URL,
		process: async(fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
			clearTimeout(uploadedFilesTimer);// Clear the previous timeout
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);

			document.getElementById('submitButton').disabled = true;
            Post(`request/${hash}/rawfile`, formData).then(response => {
				// Should call the progress method to update the progress to 100% before calling load
            
				if(response.result && response.result === 'OK'){
					progress(true, 0, 1024);
					load();
				}else{
					document.getElementById('submitButton').disabled = false;
				}
			});

            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // Let FilePond know the request has been cancelled
                    abort();
                },
            };
        },
	}
	return (
		<form className="card">
			<div className="card-body d-flex flex-column justify-content-evenly">
				<div className="row">
					<div className="col-12 d-flex justify-content-center">
						{Steps(4,3)}
					</div>
				</div>

				<div className="row gap-4" id="main">
					<div className="col-12 text-center">
						<h6>Ya casi terminamos.</h6>
						<h6><small>{configData?.text?.files_description ?? 'Suba una foto de una factura de compra reciente'}</small></h6>
					</div>
					<div className="col-12">
						<FilePond
							className="pointer"
							ref={ref => fpond = ref}
							files={files}
							onupdatefiles={setFiles}
							allowMultiple={true}
							instantUpload={false}
							maxFiles={3}
							maxFileSize='5MB'
							acceptedFileTypes={['image/*']}
							server={serverConfig}
							name="files"
							labelIdle='<span class="filepond--label-action">Tome una foto o suba un archivo</span>'
							labelFileLoading='Cargando'
							labelFileLoadError='Error cargando la imagen'
							labelFileProcessing='Enviando imagenes..'
							labelFileProcessingComplete='Envio completado'
							labelFileProcessingError='Error enviando la imagen'
							labelTapToRetry='Click para reintentar'
							labelTapToCancel='Click para cancelar'
							labelButtonRemoveItem='Quitar'
							labelButtonRetryItemLoad='Reintentar'
							labelButtonAbortItemProcessing='Cancelar'
							labelButtonProcessItem='Enviar'
							labelMaxFileSizeExceeded='La imagen es demasiado pesada'
							labelMaxFileSize='El tamaño máximo para los imagenes es {filesize}'
							labelFileTypeNotAllowed='Tipo de imagen no admitido'
							fileValidateTypeLabelExpectedTypes='Se esperan {allButLastType} o {lastType}'
							fileValidateTypeLabelExpectedTypesMap={{'image/*': 'Imagenes'}}
						/>
					</div>
					<div className="col-12">
						<button className='btn btn-success' id="submitButton" onClick={()=>{
							if(fpond.getFiles().length === 0) return viewModal(true);
							fpond.processFiles()
							.then((files)=>{
								uploadedFilesTimer = setTimeout(()=>{
									if(typeof fpond === 'undefined') return;
									let pendingFiles = fpond.getFiles().filter(x => x.status !== 5).length
									if(pendingFiles > 0) return;
									navigate('/success');
								},500)
							}).catch(err=>{
								console.log(err);
							})
						}} type='button'>Continuar
						</button>
					</div>

					<div className="col-12 mt-4 text-end">
						<a href="/success" class="terms-text">Disculpas, la enviaré mas tarde</a>
					</div>
				</div>
			</div>
			<FilesRequiredModal show={showFilesRequired} onClose={()=>{viewModal(false)}} />
		</form>
	)
}

function Files(){
	return (
		<section id="step">
			<Formulary />
		</section>
	)
}

export default Files